/* General Styling */
body {
  background-color: #FFFFFF;
  color: #000000;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

/* App Component Styling */
.App {
  text-align: center;
  width: 80%;
  max-width: 900px;
  margin: auto;
  /* border: 1px solid #333; */
  border-radius: 10px;
  padding: 20px;
}

.App-header {
  background-color: #FFFFFF;
  padding: 10px 20px 5px 20px;
  border-radius: 10px 10px 0 0;
  text-align: left;
}

h1 {
  font-size: 1.5rem;
  color: #000000;
  margin: 0;
}

/* TextInput Component Styling */
textarea {
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #292537;
  border-radius: 5px;
  background-color: #FFFFFF;
  color: #000000;
  resize: none;
}

textarea:focus {
  outline: none;
  border-color: #1967D3;
  box-shadow: 0px 0px 10px rgba(50, 168, 82, 0.3);
}

button {
  padding: 20px 50px;
  font-size: 18px;
  font-weight: 600;
  border: none;
  border-radius: 10px;
  background-color: #1967D3;
  color: #FFFFFF;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #1967D3;
}

/* AnalysisDisplay Component Styling */
main {
  padding: 10px 20px 10px 20px;
}

h2 {
  font-size: 1.5rem;
  margin-top: 40px;
  color: #000000;
  text-align: left;
}

h3 {
  margin: 20px 0 10px;
  color: #000000;
  text-align: left;
}

p {
  font-size: 0.875rem;
  white-space: pre-wrap;
  text-align: left;
  padding: 12px;
  
  background-color: #FFFFFF;
  border-radius: 1px;
}
